<template>
	<div class="login">
		<div v-title data-title="多对多SaaS管理平台"></div>
		<div class="login_content">
			<div class="login_logo">
				<img src="../assets/imgs/common/login.png" alt="">
			</div>

			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
				<el-form-item prop="name" class="login_form">
					<img class="login_form_img" src="../assets/imgs/login/user.png" alt="" >
					<el-input v-model.trim="ruleForm.name" class="input_medi" maxlength="11" placeholder="账户"></el-input>
				</el-form-item>
				<el-form-item prop="password" class="login_form">
					<img class="login_form_img" src="../assets/imgs/login/password.png" alt="" >
					<el-input v-model="ruleForm.password" maxlength="16" show-password class="input_medi" placeholder="密码"></el-input>
				</el-form-item>
				<el-form-item>
					<p class="submit" @click="submitForm('ruleForm')">登录</p>
				</el-form-item>
			</el-form>


		</div>
	</div>
</template>

<script>
	export default {
		name: 'login',
		data() {
			return {
				ruleForm: {
					name: '',
					password: '',
				},
				rules: {
					name: [
						{
							required: true,
							message: '账号不能为空',
							trigger: 'blur'
						},
						{ pattern: /^1[3456789]\d{9}$/, message: '手机号错误'},
					],
					password: [
						{
							required: true,
							message: '密码不能为空',
							trigger: 'blur'
						},
					]
				},
			}
		},
		created() {
			// 获取toekn
			this.getToken();
		},
		components: {

		},
		methods: {
			// 获取toekn
			getToken(){
				let token = window.localStorage.getItem('token');
				if(token) return;
				
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}api/accesstoken`,
					data: {}
				}).then( res => {
					if(res.code == '0'){
						localStorage.setItem("token", res.data.token);
					}
				})
				
			},
			
			
			// 登录
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}adminauth/login`,
							data: {
								mobile: this.ruleForm.name,
								password: this.ruleForm.password,
							}
						}).then( res => {
							if(res.code == 0){
								// 获取当前登录人员信息
								this.getUserInfo()
								this.$router.push('/standardGoods')
							}
							else{
								this.$message.error(`${res.msg}`);
							}
						})
						
						
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			
			// 获取当前登录人员信息
			getUserInfo(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}adminauth/info`,
					data: {}
				}).then( res => {
					if(res.code == 0){
						window.localStorage.setItem('userInfrom', JSON.stringify(res.data));
					}
				})
			},
			
			
		}
	}
</script>

<style scoped lang="less">
	.login {
		width: 100%;
		height: 100vh;
		background-color: #F0F3F7;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.input_medi {
		width: 368px;
	}

	.login_content {
		width: 370px;
		.login_logo {
			display: flex;
			justify-content: center;
			margin-bottom: 40px;
			img {
				width: 292px;
				height: 78px;
			}
		}

		.submit{
			width: 368px;
			border-radius: 2px;
			background-color: #EE7B1D;
			height: 40px;
			line-height: 40px;
			text-align: center;
			font-size: 16px;
			color: #fff;
			cursor: pointer;
		}
		
		.login_form{
			position: relative;
			.login_form_img{
				width: 16px;
				height: 16px;
				position: absolute;
				left: 10px;
				top: 12px;
				z-index: 9;
			}
		}
		
		
	}
	
	
	
	
</style>
